<script>
  import {data, notification, ajaxRequest, showLoginPage} from './stores.js';
  import {onMount, tick} from 'svelte';
  import {slide, fly, fade} from 'svelte/transition';
  import {
    getToken,
    getProfile,
    putPublisher,
    putApplication
  } from './utils/api-calls.js';
  import {
    checkURIValidity,
    checkTosPpUriError,
    checkDevURIError,
    checkProdURIError,
    sendEvent,
    endpoints,
    checkVersion,
    generateRandomString,
    pkceChallengeFromVerifier
  } from './utils/helper.js';
  import Notification from './lib/Notification.svelte';
  import Login from './lib/Login.svelte';
  import {InviteButton} from './lib/button/';
  import {GenericInput} from './lib/input/';
  import {
    DeleteApplicationModal,
    DeletePublisherModal,
    CreatePublisherAndApplicationModal,
    CreateApplicationModal,
    UnsavedChangesModal,
    CreateClientSecretModal,
    DeleteClientSecretModal,
    AppNotFoundModal
  } from './lib/modal/';
  import {AppleIcon, AndroidIcon, WebIcon} from './lib/icon/';
  import tippy from 'sveltejs-tippy';
  import dayjs from 'dayjs';

  let platformTabs = ['Web', 'iOS', 'Android'];
  let selectedPlatformTab = platformTabs[0];
  let showCreatePubAndAppModal = false;
  let showAppNotFoundModal = false; //also stores client_id
  let showCreateApplicationModal = false;
  let showDeletePublisherModal = false;
  let showDeleteApplicationModal = false;
  let showUnsavedChangesModal = null; //Holds function to execute after unsaved changes modal action
  let showUnsavedPublisherChangesModal = null; //Holds function to execute after unsaved changes modal action
  let publisherFormData = {};
  let applicationFormData = {};
  let updatePublisherAjax = false;
  let updateApplicationAjax = false;
  let copiedTooltip = false;
  let logo = null;
  let darkLogo = null;
  let selected_application = {};
  let devRedirectURIValue = '';
  let prodRedirectURIValue = '';
  let showClientSecretCreateModal = false;
  let showDeleteClientSecretModal = null; //Will hold secret to delete

  let previousActiveElement = null; //Will hold previous active element

  /**
   * If there is a change in data,
   * update the forms.
   */
  // $: $data, $data && updateFormData();

  onMount(async () => {
    $ajaxRequest = true;
    const ref = document.querySelector("link[rel='icon']");
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: light)').matches
    ) {
      ref.href = 'https://cdn.hello.coop/images/dev-favicon-light.png';
    }
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (event) => {
        if (event.matches) {
          ref.href = 'https://cdn.hello.coop/images/dev-favicon-dark.png';
        } else {
          ref.href = 'https://cdn.hello.coop/images/dev-favicon-light.png';
        }
      });

    checkVersion();

    const hash = window.location.hash && window.location.hash.substring(1);
    const search_params = new URLSearchParams(hash);
    const code = search_params.get('code');

    if (code) {
      try {
        await getToken(code);
      } catch (err) {
        console.error(err);
        $notification = {
          text: 'Something went wrong',
          type: 'error'
        };
      }
    } else {
      if (search_params.has('error')) {
        if (search_params.get('error') === 'access_denied') {
          $notification = {
            text: 'Access denied',
            type: 'error'
          };
        } else {
          $notification = {
            text: 'Something went wrong',
            type: 'error'
          };
        }

        console.error(search_params.toString());
      }

      window.location.replace('#');

      if (typeof window.history.replaceState == 'function') {
        history.replaceState({}, '', window.location.href.slice(0, -1));
      }
    }

    if (!sessionStorage.getItem('token')) {
      if (window.location.search) {
        const select_client_id = new URLSearchParams(
          window.location.search
        ).get('client_id');

        if (select_client_id) {
          sessionStorage.setItem('select_client_id', select_client_id);
        }
        window.history.pushState({}, document.title, '/');
        login();
        return;
      }
      $showLoginPage = true;
      $ajaxRequest = false;
      sendEvent({u: '/'});
      return;
    }

    try {
      $data = await getProfile();
      if ($data.isNewAdmin) {
        showCreatePubAndAppModal = true;
      }
      if ($data.defaultPublisher) {
        publisherFormData = cloneObj($data.defaultPublisher.profile);
      }
      await tick();
      if ($data.defaultPublisher) {
        const select_client_id =
          sessionStorage.getItem('select_client_id') ||
          new URLSearchParams(window.location.search).get('client_id');

        if ($data.defaultPublisher.applications?.length) {
          const lastCreatedApplication = $data.defaultPublisher.applications.sort(
            (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
          )[0];

          const selectedClientId = $data.defaultPublisher.applications?.find(
            (i) => i.id === select_client_id
          );

          sessionStorage.removeItem('select_client_id');
          if (window.location.search) {
            window.history.pushState({}, document.title, '/');
          }

          if (selectedClientId) {
            selected_application = cloneObj(selectedClientId);
          } else {
            //client id was passed, but not found
            if (select_client_id) {
              showAppNotFoundModal = select_client_id;
            }

            selected_application = cloneObj(lastCreatedApplication);
          }

          selectedView = selected_application.id;
        } else {
          if (select_client_id) {
            showAppNotFoundModal = select_client_id;
            if (window.location.search) {
              window.history.pushState({}, document.title, '/');
            }
          }
          selectedView = 'general';
        }
        updateFormData();
      }
    } catch (err) {
      console.error(err);
    }

    sendEvent({u: '/console'});
    $ajaxRequest = false;
  });

  function cloneObj(obj) {
    try {
      const clone = JSON.parse(JSON.stringify(obj));
      return clone;
    } catch (err) {
      console.error(err);
    }
  }

  async function updateFormData() {
    if (!$data.defaultPublisher) return;
    publisherFormData = cloneObj($data.defaultPublisher.profile);
    if (!$data.defaultPublisher.applications?.length) return;
    applicationFormData = cloneObj(selected_application);
  }

  async function updatePublisher() {
    try {
      updatePublisherAjax = true;
      const selected_publisher_id = $data.defaultPublisher.profile.id;
      const res = await putPublisher(selected_publisher_id, publisherFormData);
      $data.defaultPublisher.profile = res;
      $data.publishers = [res];
      publisherFormData = cloneObj(res);
    } catch (err) {
      console.error(err);
    } finally {
      updatePublisherAjax = false;
    }
  }

  async function updateApplication() {
    try {
      updateApplicationAjax = true;
      const selected_publisher_id = $data.defaultPublisher.profile.id;
      let res = cloneObj(applicationFormData);
      /**
       * API does not send createdAt property on PUT call.
       * Added here for sorting to work properly.
       */
      const createdAt = applicationFormData.createdAt;
      if (logo) {
        const inputRef = document.getElementById('logo-upload-input-ref');
        const formDataObj = new FormData();
        formDataObj.append('file', inputRef.files[0]);
        const url =
          endpoints.api +
          '/api/v1/publishers/' +
          publisherFormData.id +
          '/applications/' +
          selected_application.id +
          '/logo';
        const postLogo = await fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          },
          body: formDataObj
        });
        const json = await postLogo.json();
        res.image_uri = json.image_uri;
        logo = null;
      }
      if (darkLogo) {
        const inputRef = document.getElementById('dark-logo-upload-input-ref');
        const formDataObj = new FormData();
        formDataObj.append('file', inputRef.files[0]);
        const url =
          endpoints.api +
          '/api/v1/publishers/' +
          publisherFormData.id +
          '/applications/' +
          selected_application.id +
          '/logo';
        const postLogo = await fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          },
          body: formDataObj
        });
        const json = await postLogo.json();
        res.dark_image_uri = json.image_uri;
        darkLogo = null;
      }
      res = await putApplication(
        selected_publisher_id,
        selected_application.id,
        res
      );
      const indexOfApplication = $data.defaultPublisher.applications.findIndex(
        (i) => i.id === selected_application.id
      );
      $data.defaultPublisher.applications[indexOfApplication] = res;
      selected_application = res;
      applicationFormData = cloneObj(selected_application);
      /**
       * API does not send createdAt property on POST call.
       * Added here for sorting to work properly.
       */
      applicationFormData.createdAt = createdAt;
    } catch (err) {
      console.error(err);
    } finally {
      updateApplicationAjax = false;
    }
  }

  async function changeApplication(application) {
    selected_application = cloneObj(application);
    selectedView = selected_application.id;
    updateFormData();
    await tick();
    const scrollContainer = document.querySelector('#scroll-view');
    if (scrollYPosition && scrollContainer) {
      scrollContainer.scrollTo(0, scrollYPosition);
    }
  }

  function changePlatform(platform) {
    if (applicationFormDataModified()) {
      showUnsavedChangesModal = true;
    } else {
      selectedPlatformTab = platform;
    }
  }

  function handleDiscardChanges() {
    publisherFormData = cloneObj($data.defaultPublisher.profile);
    discardAppChanges();
    showUnsavedChangesModal();
    showUnsavedChangesModal = null;
    if (previousActiveElement) {
      previousActiveElement.focus();
      previousActiveElement = null;
    }
  }

  async function handleSaveChanges() {
    await updateApplication();
    showUnsavedChangesModal();
    showUnsavedChangesModal = null;
    if (previousActiveElement) {
      previousActiveElement.focus();
      previousActiveElement = null;
    }
  }

  function handleDiscardPublisherChanges() {
    publisherFormData = cloneObj($data.defaultPublisher.profile);
    discardPublisherChanges();
    showUnsavedPublisherChangesModal();
    showUnsavedPublisherChangesModal = null;
    if (previousActiveElement) {
      previousActiveElement.focus();
      previousActiveElement = null;
    }
  }

  async function handleSavePublisherChanges() {
    await updatePublisher();
    showUnsavedPublisherChangesModal();
    showUnsavedPublisherChangesModal = null;
    if (previousActiveElement) {
      previousActiveElement.focus();
      previousActiveElement = null;
    }
  }

  function handleLogoUpload(e, type) {
    let image = e.target?.files?.[0] || e[0];
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = (file) => {
      if (type === 'light') {
        logo = file.target.result;
      } else {
        darkLogo = file.target.result;
      }
    };
  }

  $: saveDisabled = () => {
    if (
      updatePublisherAjax ||
      !publisherFormData.name?.length ||
      updateApplicationAjax ||
      !applicationFormData.name?.length ||
      (applicationFormData.tos_uri?.length &&
        !checkURIValidity(applicationFormData.tos_uri)) ||
      (applicationFormData.pp_uri?.length &&
        !checkURIValidity(applicationFormData.pp_uri))
    )
      return true;
    else return false;
  };

  $: publisherFormDataModified = () => {
    return (
      $data.defaultPublisher &&
      publisherFormData?.id &&
      JSON.stringify(publisherFormData) !==
        JSON.stringify($data.defaultPublisher?.profile)
    );
  };

  $: applicationFormDataModified = () => {
    if (!applicationFormData || !selected_application) return;
    const _selected_application = cloneObj(selected_application);
    const _applicationFormData = cloneObj(applicationFormData);
    if (_selected_application.pp_uri === null)
      _selected_application.pp_uri = '';
    if (_selected_application.tos_uri === null)
      _selected_application.tos_uri = '';
    if (_applicationFormData.pp_uri === null) _applicationFormData.pp_uri = '';
    if (_applicationFormData.tos_uri === null)
      _applicationFormData.tos_uri = '';
    /**
     * API does not send createdAt property on PUT call.
     * Added here for sorting to work properly.
     */
    _selected_application.createdAt = _applicationFormData.createdAt;
    return (
      JSON.stringify(_applicationFormData) !==
        JSON.stringify(_selected_application) ||
      logo ||
      darkLogo
    );
  };

  let disableRedirectEdit = false;
  //TODO: Refactor
  $: applicationFormData &&
    applicationFormData.web?.dev &&
    applicationFormData.web?.prod &&
    checkRedirectLength();

  function checkRedirectLength() {
    if (!applicationFormData) return;
    const all_redirect_uris = [
      ...applicationFormData.web?.prod?.redirect_uris,
      ...applicationFormData.web?.dev?.redirect_uris,
      applicationFormData.web?.dev?.localhost,
      applicationFormData.web?.dev?.['127.0.0.1']
    ];

    if (all_redirect_uris.filter((i) => !!i).length <= 1) {
      disableRedirectEdit = true;
    } else {
      disableRedirectEdit = false;
    }
  }

  let previewURL = '';
  $: (applicationFormData ||
    applicationFormData.web?.dev ||
    applicationFormData.web?.prod ||
    devRedirectURIValue ||
    prodRedirectURIValue) &&
    getPreviewURL();

  $: tosURIValidity = () => {
    if (applicationFormData?.tos_uri) {
      return checkTosPpUriError(applicationFormData.tos_uri);
    } else return false;
  };

  $: ppURIValidity = () => {
    if (applicationFormData?.pp_uri) {
      return checkTosPpUriError(applicationFormData.pp_uri);
    } else return false;
  };

  $: devRedirectURIValidity = () => {
    if (devRedirectURIValue) {
      return checkDevURIError(devRedirectURIValue);
    } else return false;
  };

  $: prodRedirectURIValidity = () => {
    if (prodRedirectURIValue) {
      return checkProdURIError(prodRedirectURIValue);
    } else return false;
  };

  function getPreviewURL() {
    let _previewURL = '';
    const lastProdRedirectURI =
      applicationFormData?.web?.prod?.redirect_uris?.length &&
      applicationFormData.web.prod.redirect_uris[
        applicationFormData.web.prod.redirect_uris.length - 1
      ];
    const lastDevRedirectURI =
      applicationFormData?.web?.dev?.redirect_uris?.length &&
      applicationFormData.web.dev.redirect_uris[
        applicationFormData.web.dev.redirect_uris.length - 1
      ];

    if (checkURIValidity(prodRedirectURIValue)) {
      _previewURL = prodRedirectURIValue;
    } else if (checkURIValidity(lastProdRedirectURI)) {
      _previewURL = lastProdRedirectURI;
    } else if (checkURIValidity(devRedirectURIValue)) {
      _previewURL = devRedirectURIValue;
    } else if (checkURIValidity(lastDevRedirectURI)) {
      _previewURL = lastDevRedirectURI;
    } else if (applicationFormData.web?.dev?.localhost) {
      _previewURL = 'http://localhost';
    } else if (applicationFormData.web?.dev?.['127.0.0.1']) {
      _previewURL = 'http://127.0.0.1';
    }

    try {
      const url = new URL(_previewURL);
      previewURL = url;
    } catch (err) {
      previewURL = new URL('http://localhost');
    }
  }

  async function copy() {
    copiedTooltip = true;
    await navigator.clipboard.writeText(selected_application.id);

    setTimeout(() => {
      copiedTooltip = false;
    }, 1000);
  }

  const openIDConfig = window.endpoints.issuer;
  let openIDConfigCopiedTooltip = false;
  async function copyOpenIDConfig() {
    openIDConfigCopiedTooltip = true;
    await navigator.clipboard.writeText(openIDConfig);

    setTimeout(() => {
      openIDConfigCopiedTooltip = false;
    }, 1000);
  }

  function handleLogoDrop(e, type) {
    e.preventDefault();
    if (!e.dataTransfer) return null;
    const fileName = e.dataTransfer.files[0].name;
    const acceptedFileTypes = [
      '.png',
      '.gif',
      '.jpg',
      '.jpeg',
      '.webp',
      '.apng'
    ];
    let valid = false;
    for (const fileType of acceptedFileTypes) {
      if (fileName.endsWith(fileType)) valid = true;
    }
    if (!valid) {
      $notification = {
        text:
          'Please upload a valid image format (.png, .gif, .jpg, .jpeg, .webp, .apng)',
        type: 'error'
      };
      return;
    }
    if (type === 'light') {
      document.getElementById('logo-upload-input-ref').files =
        e.dataTransfer.files;
      handleLogoUpload(e.dataTransfer.files, 'light');
    } else {
      document.getElementById('dark-logo-upload-input-ref').files =
        e.dataTransfer.files;
      handleLogoUpload(e.dataTransfer.files, 'dark');
    }
  }

  function discardAppChanges() {
    applicationFormData = cloneObj(selected_application);
    logo = darkLogo = null;
  }

  function discardPublisherChanges() {
    publisherFormData = cloneObj($data.defaultPublisher.profile);
  }

  let selectedView;

  const links = [
    {
      text: 'Documentation',
      link: 'https://hello.dev/documentation/'
    },
    {
      text: 'Playground',
      link: 'https://playground.hello.dev/'
    }
  ];

  async function logout() {
    sessionStorage.removeItem('token');
    await sendEvent({u: '/logout', n: 'action'});
    window.location.reload();
  }

  let showProfileDropdown = false;
  let mobileMenu = false;

  // $: if (mobileMenu) {
  //   document.body.style.overflow = 'auto';
  // } else {
  //   document.body.style.overflow = 'hidden';
  // }

  let scrollYPosition;

  let loginAjax = false,
    updateEmailAjax = false,
    updatePictureAjax = false
  async function login({update = false, claim = ""} = {}) {
    try {
      if (update) {
        if(claim === "email") {
          updateEmailAjax = true;
        } else if(claim === "picture") {
          updatePictureAjax = true
        }
      } else {
        loginAjax = true;
      }
      const state = generateRandomString();
      localStorage.setItem('pkce_state', state);
      const code_verifier = generateRandomString();
      localStorage.setItem('pkce_code_verifier', code_verifier);

      const code_challenge = await pkceChallengeFromVerifier(code_verifier);

      const url = new URL(window.endpoints.authorization_server);
      url.searchParams.set('client_id', 'hello_console');
      url.searchParams.set(
        'redirect_uri',
        window.location.origin + window.location.pathname
      );
      url.searchParams.set('response_type', 'code');
      url.searchParams.set('response_mode', 'fragment');
      url.searchParams.set('state', '');
      url.searchParams.set('code_challenge', code_challenge);
      url.searchParams.set('code_challenge_method', 'S256');
      if (update) {
        url.searchParams.set(
          'scope',
          'console profile_update ' + claim
        );
        await sendEvent({u: '/update-profile', n: 'action'});
      } else {
        url.searchParams.set('scope', 'console name email picture');
        await sendEvent({u: '/start/login', n: 'action'});
      }
      window.location.href = url.href;
    } catch (err) {
      if (update) {
        updateEmailAjax = false;
        updatePictureAjax = false
      }
      console.error(err);
    }
  }
</script>

<nav
  id="top-nav"
  data-test="test-run-5"
  class="overflow-auto w-full flex-shrink-0 relative z-50 h-12 px-4 text-white dark:text-[#d4d4d4] bg-charcoal flex items-center justify-between"
>
  <div class="inline-flex items-center xl:w-2/5">
    <button
      aria-label="menu"
      on:click={() => (mobileMenu = !mobileMenu)}
      class="mr-2 mt-0.5 group md:hidden"
    >
      {#if mobileMenu}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 stroke-2 group-hover:stroke-3 group-focus-visible:stroke-3 text-[#d4d4d4]"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      {:else}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 stroke-2 group-hover:stroke-3 group-focus-visible:stroke-3 text-[#d4d4d4]"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            class="stroke-2 group-hover:stroke-3 group-focus-visible:stroke-3"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      {/if}
    </button>

    <a
      href="https://hello.dev"
      target="_blank"
      class="hidden md:inline-flex items-center font-medium text-xl link"
    >
      <span>hello.dev</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 ml-1 mt-0.5 opacity-80"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
        />
      </svg>
    </a>
  </div>

  <div
    class="flex-shrink-0 xl:flex-1 text-center block font-semibold text-xl mx-auto md:mx-0 relative right-2.5 md:right-0"
  >
    Developer Console
  </div>

  <div class="hidden md:flex items-center justify-end xl:w-2/5">
    <div class="space-x-4">
      {#each links as { text, link }}
        <button
          on:click={async () => {
            await sendEvent({u: `/${text.toLowerCase()}`, n: 'action'});
            window.open(link, '_blank');
          }}
          class="inline-flex items-center link text-sm relative"
        >
          {text}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 ml-1 mt-0.5 opacity-80"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
            />
          </svg>
        </button>
      {/each}
    </div>

    {#if $data && $data.admin}
      <button
        data-test="profile-options-btn"
        on:click={() => {
          showProfileDropdown = !showProfileDropdown;
        }}
        class="inline-flex items-center ml-4 flex-shrink-0 group"
      >
        <div class="inline-flex items-center text-sm">
          <img
            src={$data.admin.picture}
            alt="Photo of {$data.admin.name}"
            class="rounded-full w-6 h-6 object-cover"
          />
          <span
            class="mx-2 underline-offset-4 decoration-2 group-hover:underline group-focus-visible:underline"
            >{$data.admin.email}</span
          >
        </div>
        <div>
          {#if showProfileDropdown}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                class="stroke-2 group-hover:stroke-3 group-focus-visible:stroke-3"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          {:else}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                class="stroke-2 group-hover:stroke-3 group-focus-visible:stroke-3"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          {/if}
        </div>
      </button>
    {/if}
  </div>
</nav>

{#if mobileMenu}
  <div
    class="max-h-[75%] overflow-y-auto bg-charcoal md:hidden absolute top-12 text-white dark:text-[#d4d4d4] w-full pt-4 px-4 pb-6 z-50"
    transition:slide
  >
    <div
      class="inline-flex flex-col items-start gap-y-4"
      class:pb-6={$data?.admin}
    >
      <a
        href="https://hello.dev"
        target="_blank"
        class="md:hidden inline-flex items-center link"
      >
        <span>hello.dev</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 ml-1 mt-0.5 opacity-80"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
          />
        </svg>
      </a>
      {#each links as { text, link }}
        <button
          class="link inline-flex items-center relative"
          on:click={async () => {
            await sendEvent({u: `/${text.toLowerCase()}`, n: 'action'});
            window.open(link, '_blank');
          }}
        >
          {text}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 ml-1 mt-0.5 opacity-80"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
            />
          </svg>
        </button>
      {/each}
    </div>

    {#if $data?.admin}
      <div
        class="inline-flex md:hidden items-center border-y-2 w-full pt-6 pb-8 border-white border-opacity-20"
      >
        <nav
          aria-label="Publishers navigation"
          class="flex flex-col flex-shrink-0 w-full"
        >
          <div class="inline-flex items-center mb-2">
            <h1 class="font-medium text-lg text-white dark:text-[#d4d4d4]">
              Publisher
            </h1>
            <div class="ml-1 h-5">
              <button
                use:tippy={{
                  content:
                    'A publisher is one or more people working on one or more applications.',
                  placement: 'right'
                }}
                class="text-white dark:text-[#d4d4d4]"
                aria-label="Help"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 ml-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>

          {#if $data?.publishers?.length}
            <div class="relative flex items-center">
              <select
                class="cursor-pointer flex-1 h-9 px-4 block btn-create rounded-md ring-white dark:ring-[#808080] bg-transparent text-white dark:text-[#d4d4d4] mt-1 appearance-none truncate"
                on:change={() => (mobileMenu = false)}
              >
                {#each $data.publishers as publisher (publisher.id)}
                  <option>{publisher.name}</option>
                {/each}
                <option disabled>Coming Soon</option>
              </select>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                class="w-4 h-4 absolute right-4 top-[14px] pointer-events-none text-white dark:text-[#d4d4d4]"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </div>
          {:else}
            <div class="mt-3">
              <button
                on:click={() => {
                  mobileMenu = false;
                  previousActiveElement =
                    document.activeElement || document.body;
                  showCreatePubAndAppModal = true;
                }}
                class="btn-create ring-white dark:ring-[#d4d4d4] bg-transparent text-white dark:text-[#d4d4d4]"
              >
                Create Publisher
              </button>
            </div>
          {/if}

          {#if $data.publishers?.length}
            <button
              on:click={() => {
                mobileMenu = false;
                if (selectedView === 'general') return;
                if (applicationFormDataModified()) {
                  showUnsavedChangesModal = () => {
                    selectedView = 'general';
                  };
                } else {
                  selectedView = 'general';
                }
              }}
              class="truncate h-9 flex justify-start items-center rounded-md mt-3 mb-3 text-white dark:text-[#d4d4d4] group"
            >
              <span
                class="w-4 h-4 rounded-full {selectedView === 'general'
                  ? 'bg-white dark:bg-[#d4d4d4]'
                  : 'border border-white border-opacity-50'} border border-white inline-flex items-center justify-center"
              />
              <span
                class="truncate ml-2 underline-offset-4 decoration-2 group-hover:underline group-focus-visible:underline"
                >Members</span
              >
            </button>

            <h1 class="font-medium text-lg text-white dark:text-[#d4d4d4] mb-2">
              Applications
            </h1>
          {/if}

          <div class="space-y-3 flex flex-col">
            {#if $data.defaultPublisher?.applications?.length}
              {#each $data.defaultPublisher.applications.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)) as application, index (application.id)}
                <button
                  on:click={() => {
                    mobileMenu = false;
                    if (
                      selected_application.id === application.id &&
                      selectedView !== 'general'
                    )
                      return;
                    if (applicationFormDataModified()) {
                      showUnsavedChangesModal = changeApplication.bind(
                        this,
                        application
                      );
                    } else if (publisherFormDataModified()) {
                      showUnsavedPublisherChangesModal = changeApplication.bind(
                        this,
                        application
                      );
                    } else {
                      changeApplication(application);
                    }
                  }}
                  class="truncate h-9 flex justify-start items-center text-white dark:text-[#d4d4d4] group"
                >
                  <span
                    class="w-4 h-4 rounded-full {selectedView === application.id
                      ? 'bg-white dark:bg-[#d4d4d4]'
                      : 'border border-white border-opacity-50'} inline-flex items-center justify-center"
                  />
                  <span
                    class="truncate ml-2 underline-offset-4 decoration-2 group-hover:underline group-focus-visible:underline"
                    >{application.name}</span
                  >
                </button>
              {/each}
            {/if}
          </div>

          {#if $data.defaultPublisher}
            <div
              class={$data?.defaultPublisher?.applications?.length
                ? 'mt-3'
                : 'mt-1'}
            >
              <button
                class="btn-create ring-white dark:ring-[#808080] bg-transparent text-white dark:text-[#d4d4d4]"
                on:click={() => {
                  mobileMenu = false;
                  if ($data?.defaultPublisher?.applications?.length >= 10) {
                    setTimeout(() => {
                      $notification = {
                        text: 'You cannot create more than 10 applications',
                        type: 'error'
                      };
                    }, 10);
                    return;
                  }
                  /**
                   * TODO: This is hack to add tos_uri & pp_uri to publisher object
                   * because POST response does not include these.
                   *
                   * */
                  if (
                    $data?.defaultPublisher?.applications?.length &&
                    !selected_application.hasOwnProperty('pp_uri')
                  ) {
                    selected_application.pp_uri = '';
                  }

                  if (
                    $data?.defaultPublisher?.applications?.length &&
                    !selected_application.hasOwnProperty('tos_uri')
                  ) {
                    selected_application.tos_uri = '';
                  }
                  /**
                   * TODO: This is hack to add tos_uri & pp_uri to publisher object
                   * because POST response does not include these.
                   *
                   * */

                  previousActiveElement =
                    document.activeElement || document.body;

                  if (applicationFormDataModified()) {
                    showUnsavedChangesModal = () =>
                      (showCreateApplicationModal = true);
                  } else if (publisherFormDataModified()) {
                    showUnsavedPublisherChangesModal = () =>
                      (showCreateApplicationModal = true);
                  } else {
                    showCreateApplicationModal = true;
                  }
                }}
              >
                Create Application
              </button>
            </div>
          {/if}
        </nav>
      </div>

      <div class="inline-flex items-center w-full pt-7">
        <div class="inline-flex items-center">
          <img
            src={$data.admin.picture}
            alt="Photo of {$data.admin.name}"
            class="rounded-full w-8 h-8 object-cover"
          />
          <span class="mx-2">{$data.admin.email}</span>
        </div>
      </div>

      <ul class="flex flex-col text-white dark:text-[#d4d4d4]">
        <li class="mt-2 flex items-center justify-start">
          <button
            on:click={() => login({update: true, claim: "picture"})}
            class="hello-btn-black-on-dark w-[265px]"
            style="line-height: normal;"
            class:hello-btn-loader={updatePictureAjax}
            class:justify-start={!updatePictureAjax}
            disabled={updatePictureAjax}
          >
            ō&nbsp;&nbsp;&nbsp;Update Picture with Hellō
          </button>
        </li>
        <li class="mt-3 flex items-center justify-start">
          <button
            on:click={() => login({update: true, claim: "email"})}
            class="hello-btn-black-on-dark w-[265px]"
            style="line-height: normal;"
            class:hello-btn-loader={updateEmailAjax}
            class:justify-start={!updateEmailAjax}
            disabled={updateEmailAjax}
          >
            ō&nbsp;&nbsp;&nbsp;Update Email with Hellō
          </button>
        </li>
        <li class="mt-6">
          <button on:click={logout} class="link">Log Out</button>
        </li>
      </ul>
    {/if}
  </div>

  <div
    on:click={() => (mobileMenu = false)}
    class="md:hidden fixed top-0 z-40 bg-black bg-opacity-60 w-full h-full"
  />
{/if}

{#if showProfileDropdown && $data?.admin}
  <ul
    class="space-y-3 px-5 pt-5 hidden md:flex flex-col fixed top-12 right-0 z-50 bg-charcoal text-white dark:text-[#d4d4d4]"
  >
    <li class="flex justify-end">
      <button
        on:click={() => login({update: true, claim: "picture"})}
        class="hello-btn-black-on-dark w-[265px]"
        style="line-height: normal;"
        class:hello-btn-loader={updatePictureAjax}
        class:justify-start={!updatePictureAjax}
        disabled={updatePictureAjax}
      >
        ō&nbsp;&nbsp;&nbsp;Update Picture with Hellō
      </button>
    </li>
    <li class="flex justify-end">
      <button
        on:click={() => login({update: true, claim: "email"})}
        class="hello-btn-black-on-dark w-[265px]"
        style="line-height: normal;"
        class:hello-btn-loader={updateEmailAjax}
        class:justify-start={!updateEmailAjax}
        disabled={updateEmailAjax}
      >
        ō&nbsp;&nbsp;&nbsp;Update Email with Hellō
      </button>
    </li>
    <li class="flex py-4 justify-end border-t border-white border-opacity-20">
      <button data-test="logout-btn" on:click={logout} class="link"
        >Log Out</button
      >
    </li>
  </ul>
{/if}

{#if showProfileDropdown}
  <div
    on:click={() => (showProfileDropdown = false)}
    transition:fade
    class="fixed top-0 z-40 bg-black bg-opacity-60 w-full h-full"
  />
{/if}

{#if $notification}
  <Notification />
{/if}

{#if showCreatePubAndAppModal}
  <CreatePublisherAndApplicationModal
    on:close={() => {
      showCreatePubAndAppModal = false;
      if (previousActiveElement) {
        previousActiveElement.focus();
        previousActiveElement = null;
      }
    }}
    on:success={() => {
      publisherFormData = cloneObj($data.defaultPublisher.profile);
      selected_application = cloneObj($data.defaultPublisher.applications[0]);
      selectedView = selected_application.id;
      updateFormData();
    }}
  />
{/if}

{#if showAppNotFoundModal}
  <AppNotFoundModal
    clientId={showAppNotFoundModal}
    on:ok={() => (showAppNotFoundModal = false)}
  />
{/if}

{#if showUnsavedChangesModal}
  <UnsavedChangesModal
    on:discard={handleDiscardChanges}
    on:cancel={() => {
      showUnsavedChangesModal = null;
      if (previousActiveElement) {
        previousActiveElement.focus();
        previousActiveElement = null;
      }
    }}
    on:save={handleSaveChanges}
    ajaxRequest={updateApplicationAjax}
    {saveDisabled}
  />
{/if}

{#if showUnsavedPublisherChangesModal}
  <UnsavedChangesModal
    on:discard={handleDiscardPublisherChanges}
    on:cancel={() => {
      showUnsavedPublisherChangesModal = null;
      if (previousActiveElement) {
        previousActiveElement.focus();
        previousActiveElement = null;
      }
    }}
    on:save={handleSavePublisherChanges}
    ajaxRequest={updatePublisherAjax}
    {saveDisabled}
  />
{/if}

{#if showCreateApplicationModal}
  <CreateApplicationModal
    on:success={(e) => {
      selected_application = cloneObj(e.detail);
      selectedView = selected_application.id;
      updateFormData();

      // Hand back input focus to application name
      if (document.getElementById('application-name'))
        document.getElementById('application-name').focus();
    }}
    on:close={() => {
      showCreateApplicationModal = false;

      if (previousActiveElement) {
        previousActiveElement.focus();
        previousActiveElement = null;
      }

      // Hand back input focus to application name
      if (document.getElementById('application-name'))
        document.getElementById('application-name').focus();
    }}
  />
{/if}

{#if showClientSecretCreateModal}
  <CreateClientSecretModal
    pub_id={$data.defaultPublisher.profile.id}
    app_id={selected_application.id}
    on:success={(e) => {
      selected_application.secrets = e.detail;
      const app = $data?.defaultPublisher?.applications?.find(
        (app) => app.id === selected_application.id
      );
      if (app !== null) {
        app.secrets = e.detail;
      }
      updateFormData();
    }}
    on:close={() => {
      showClientSecretCreateModal = false;
      if (previousActiveElement) {
        previousActiveElement.focus();
        previousActiveElement = null;
      }
    }}
  />
{/if}

{#if showDeleteApplicationModal}
  <DeleteApplicationModal
    application={selected_application}
    on:close={() => {
      showDeleteApplicationModal = false;

      // Hand back input focus to application name
      if (document.getElementById('application-name'))
        document.getElementById('application-name').focus();
    }}
    on:success={() => {
      if (!$data.defaultPublisher?.applications?.length) {
        selectedView = 'general';
      } else {
        const lastCreatedApplication = $data.defaultPublisher.applications.sort(
          (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
        )[0];
        selected_application = cloneObj(lastCreatedApplication);
        selectedView = selected_application.id;
        updateFormData();

        // Hand back input focus to application name
        if (document.getElementById('application-name'))
          document.getElementById('application-name').focus();
      }
    }}
  />
{/if}

{#if showDeleteClientSecretModal}
  <DeleteClientSecretModal
    pub_id={$data.defaultPublisher.profile.id}
    application={selected_application}
    secret={showDeleteClientSecretModal}
    on:close={() => {
      showDeleteClientSecretModal = null;
    }}
    on:success={() => {
      delete selected_application.secrets[showDeleteClientSecretModal];
      selected_application.secrets = selected_application.secrets;
      updateFormData();
    }}
  />
{/if}

{#if showDeletePublisherModal}
  <DeletePublisherModal on:close={() => (showDeletePublisherModal = false)} />
{/if}

{#if $ajaxRequest}
  <div class="spinner" />
{:else}
  {#if $data}
    <main class="h-full flex flex-1 text-sm -mt-12 pt-12">
      <nav
        aria-label="Publishers navigation"
        class="hidden md:flex flex-col flex-shrink-0 bg-charcoal w-72 px-4"
      >
        <div class="inline-flex items-center mt-4 mb-2">
          <h1 class="font-medium text-lg text-white dark:text-[#d4d4d4]">
            Publisher
          </h1>
          <div class="ml-1 h-5">
            <button
              use:tippy={{
                content:
                  'A publisher is one or more people working on one or more applications.',
                placement: 'right'
              }}
              class="text-white dark:text-[#d4d4d4]"
              aria-label="Help"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 ml-1"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>

        {#if $data.publishers.length}
          <div class="relative flex items-center ml-2">
            <select
              data-test="select-publisher"
              class="cursor-pointer flex-1 h-9 px-4 block btn-create rounded-md ring-white dark:ring-[#808080] bg-transparent text-white dark:text-[#d4d4d4] mt-1 appearance-none truncate"
            >
              {#each $data.publishers as publisher (publisher.id)}
                <option>{publisher.name}</option>
              {/each}
              <option disabled>Coming Soon</option>
            </select>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-4 h-4 absolute right-4 top-[14px] pointer-events-none text-white dark:text-[#d4d4d4]"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </div>
        {:else}
          <div class="mt-3">
            <button
              data-test="create-publisher-btn"
              on:click={() => {
                previousActiveElement = document.activeElement || document.body;
                showCreatePubAndAppModal = true;
              }}
              class="btn-create ring-white dark:ring-[#808080] bg-transparent text-white dark:text-[#d4d4d4]"
            >
              Create Publisher
            </button>
          </div>
        {/if}

        <!-- <div class="mt-4 border-t border-white border-opacity-50 w-full" /> -->

        {#if $data.publishers?.length}
          <button
            data-test="settings-btn"
            on:click={() => {
              if (selectedView === 'general') return;
              if (applicationFormDataModified()) {
                showUnsavedChangesModal = () => {
                  selectedView = 'general';
                };
              } else {
                selectedView = 'general';
              }
            }}
            class="h-9 flex justify-start items-center rounded-md mt-3 ml-2 mb-3 text-white dark:text-[#d4d4d4] group"
          >
            <span
              class="w-4 h-4 rounded-full {selectedView === 'general'
                ? 'bg-white dark:bg-[#d4d4d4]'
                : 'border border-white border-opacity-50'} border border-white inline-flex items-center justify-center"
            />
            <span
              class="ml-2 group-hover:underline group-focus-visible:underline underline-offset-4 decoration-2"
              >Members</span
            ></button
          >

          <h1 class="font-medium text-lg text-white dark:text-[#d4d4d4] mb-2">
            Applications
          </h1>
        {/if}

        <div class="space-y-1 ml-2 flex flex-col">
          {#if $data.defaultPublisher?.applications?.length}
            {#each $data.defaultPublisher.applications.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)) as application, index (application.id)}
              <button
                data-test={`application-${index}`}
                on:click={() => {
                  if (
                    selected_application.id === application.id &&
                    selectedView !== 'general'
                  )
                    return;
                  if (applicationFormDataModified()) {
                    showUnsavedChangesModal = changeApplication.bind(
                      this,
                      application
                    );
                  } else if (publisherFormDataModified()) {
                    showUnsavedPublisherChangesModal = changeApplication.bind(
                      this,
                      application
                    );
                  } else {
                    changeApplication(application);
                  }
                }}
                class="h-9 flex justify-start items-center text-white dark:text-[#d4d4d4] group"
              >
                <span
                  class="w-4 h-4 rounded-full {selectedView === application.id
                    ? 'bg-white dark:bg-[#d4d4d4]'
                    : 'border border-white border-opacity-50'} inline-flex items-center justify-center"
                />
                <span
                  class="ml-2 text-left group-hover:underline group-focus-visible:underline underline-offset-4 decoration-2"
                  >{application.name}</span
                >
              </button>
            {/each}
          {/if}
        </div>

        {#if $data.defaultPublisher}
          <div
            class="ml-2 {$data?.defaultPublisher?.applications?.length
              ? 'mt-4'
              : 'mt-1'}"
          >
            <button
              data-test="create-application-btn"
              class="btn-create ring-white dark:ring-[#808080] bg-transparent text-white dark:text-[#d4d4d4]"
              on:click={() => {
                if ($data?.defaultPublisher?.applications?.length >= 10) {
                  setTimeout(() => {
                    $notification = {
                      text: 'You cannot create more than 10 applications',
                      type: 'error'
                    };
                  }, 10);
                  return;
                }
                /**
                 * TODO: This is hack to add tos_uri & pp_uri to publisher object
                 * because POST response does not include these.
                 *
                 * */
                if (
                  $data?.defaultPublisher?.applications?.length &&
                  !selected_application.hasOwnProperty('pp_uri')
                ) {
                  selected_application.pp_uri = '';
                }

                if (
                  $data?.defaultPublisher?.applications?.length &&
                  !selected_application.hasOwnProperty('tos_uri')
                ) {
                  selected_application.tos_uri = '';
                }
                /**
                 * TODO: This is hack to add tos_uri & pp_uri to publisher object
                 * because POST response does not include these.
                 *
                 * */

                previousActiveElement = document.activeElement || document.body;

                if (applicationFormDataModified()) {
                  showUnsavedChangesModal = () =>
                    (showCreateApplicationModal = true);
                } else if (publisherFormDataModified()) {
                  showUnsavedPublisherChangesModal = () =>
                    (showCreateApplicationModal = true);
                } else {
                  showCreateApplicationModal = true;
                }
              }}
            >
              Create Application
            </button>
          </div>
        {/if}
      </nav>

      <section
        class="h-full flex-1 pt-5 pb-16 w-full overflow-y-auto"
        id="scroll-view"
        on:scroll={(e) => {
          if (selectedView !== 'general') {
            scrollYPosition = e.target.scrollTop;
          }
        }}
      >
        {#if selectedView === 'general'}
          <div
            on:keydown={(e) => {
              //Handle escape key to discard changes
              //27 escape key code
              if (e.keyCode === 27) {
                discardPublisherChanges();
              }
            }}
            class="w-full max-w-3xl pr-4"
          >
            {#if $data.publishers?.length && publisherFormData?.id}
              <div class="space-y-4">
                <div class="mt-1 -mr-4">
                  <GenericInput
                    name="Publisher Name"
                    id="publisher-name"
                    edited={publisherFormData.name !==
                      $data.defaultPublisher?.profile?.name}
                    bind:value={publisherFormData.name}
                    autofocus
                  />
                </div>

                <div class="pl-4">
                  <div class="flex items-center justify-between relative">
                    <div class="inline-flex items-center">
                      <label for="administrator" class="font-medium text-base"
                        >Administrators</label
                      >
                      <button
                        aria-label="Help"
                        use:tippy={{
                          content: 'Full control',
                          placement: 'right'
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 ml-1"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                    <InviteButton />

                    <p
                      class="text-xs text-right mt-16 ml-4 opacity-60 absolute right-0"
                    >
                      COMING SOON
                    </p>
                  </div>
                  <div id="administrator" class="mt-1 ml-4 flex items-center">
                    <img
                      src={$data.admin?.picture}
                      alt={$data.admin?.name}
                      data-test="admin-picture"
                      class="w-9 h-9 rounded-full object-cover flex-shrink-0"
                    />

                    <div class="flex flex-col text-sm ml-3">
                      <span data-test="admin-name">{$data.admin?.name}</span>
                      <span data-test="admin-email">{$data.admin?.email}</span>
                    </div>
                  </div>
                </div>

                <div
                  class="ml-4 border-t border-charcoal dark:border-white border-opacity-20 dark:border-opacity-20 pt-6"
                >
                  <div class="flex justify-between items-start">
                    <div class="inline-flex items-center">
                      <label for="testers" class="font-medium text-base"
                        >Testers</label
                      >
                      <button
                        aria-label="Help"
                        use:tippy={{
                          content: 'Can view console',
                          placement: 'right'
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 ml-1"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                    <InviteButton />
                  </div>

                  <p
                    id="testers"
                    class="text-xs text-right mt-3 ml-4 opacity-60"
                  >
                    COMING SOON
                  </p>
                </div>

                <div
                  class="ml-4 border-t border-charcoal dark:border-white border-opacity-20 dark:border-opacity-20 pt-6"
                >
                  <p class="text-sm italic font-medium">
                    Only these people have access to Development Redirect URIs.
                  </p>
                </div>
              </div>
            {:else}
              <div class="px-4">
                Create a publisher first to create an application.
              </div>
            {/if}

            <div class="gap-4 mt-6 flex justify-between items-center flex-wrap">
              {#if publisherFormDataModified()}
                <div class="flex px-4">
                  <button
                    data-test="save-publisher-changes-btn"
                    on:click={updatePublisher}
                    class="btn-background flex-shrink-0 !w-36 !h-9 block"
                    disabled={updatePublisherAjax ||
                      !publisherFormData.name?.length}
                  >
                    {#if updatePublisherAjax}
                      <svg
                        class="animate-spin h-5 w-5 block mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        />
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    {:else}
                      Save changes
                    {/if}
                  </button>

                  <button
                    data-test="discard-publisher-changes-btn"
                    on:click={discardPublisherChanges}
                    class="btn-border ml-2 flex-shrink-0 !w-36 !h-9 block"
                  >
                    Discard changes
                  </button>
                </div>
              {/if}

              {#if $data.defaultPublisher && publisherFormData?.id}
                <div class="ml-auto">
                  <button
                    data-test="delete-publisher-btn"
                    on:click={() => (showDeletePublisherModal = true)}
                    disabled={$data.defaultPublisher?.applications?.length}
                    class="btn-danger w-40 h-9">Delete Publisher</button
                  >
                </div>
              {/if}
            </div>
          </div>
        {:else if $data.publishers?.length && $data.defaultPublisher?.applications?.length && selected_application}
          <h2 class="pl-4 mb-4 text-xl font-medium md:hidden">
            {$data?.defaultPublisher?.profile?.name}
          </h2>

          {#if $data.publishers?.length}
            <div
              on:keydown={(e) => {
                //Handle escape key to discard changes
                //27 escape key code
                if (e.keyCode === 27) {
                  discardAppChanges();
                }
              }}
              class="space-y-4"
            >
              <span
                data-test="created-info"
                class="italic text-base font-light px-4 block"
              >
                Created on {dayjs(applicationFormData.createdAt).format(
                  'MMMM DD, YYYY'
                )} with
                {#if applicationFormData.createdBy?.startsWith('quickstart')}
                  Quickstart {applicationFormData.createdBy.split('|')[1]
                    ? 'and ' + applicationFormData.createdBy.split('|')[1]
                    : ''}
                {:else if 'createdBy' in applicationFormData && applicationFormData.createdBy !== 'console'}
                  {applicationFormData.createdBy}
                {:else}
                  Console
                {/if}
              </span>

              <div class="mt-1 max-w-3xl">
                <GenericInput
                  name="Application Name"
                  id="application-name"
                  edited={applicationFormData.name !==
                    selected_application.name}
                  bind:value={applicationFormData.name}
                  autofocus
                />
              </div>

              <div class="w-full flex flex-col max-w-3xl">
                <label for="terms-of-service" class="px-4 font-medium text-base"
                  >Terms of Service</label
                >
                <div
                  class="py-1.5 pl-4 flex flex-col"
                  class:bg-green-400={(applicationFormData.tos_uri ||
                    selected_application.tos_uri) &&
                    applicationFormData.tos_uri !==
                      selected_application.tos_uri &&
                    !tosURIValidity()}
                  class:bg-red-400={selected_application.tos_uri?.length &&
                    !applicationFormData.tos_uri?.length}
                >
                  <input
                    id="terms-of-service"
                    name="Terms of Service"
                    placeholder="https://example.com/terms-of-service.html"
                    type="link"
                    bind:value={applicationFormData.tos_uri}
                    class="h-9 px-3 mx-4 rounded-md bg-white dark:bg-[#151515] dark:placeholder-[#808080] dark:placeholder-opacity-80"
                    class:border-red-500={tosURIValidity()}
                  />

                  {#if tosURIValidity()}
                    <span
                      data-test="tos-error-text"
                      class="px-4 mt-2 text-sm text-red-500"
                      transition:slide|local>{tosURIValidity()}</span
                    >
                  {/if}
                </div>
              </div>

              <div class="w-full flex flex-col max-w-3xl">
                <label for="privacy-policy" class="px-4 font-medium text-base"
                  >Privacy Policy</label
                >
                <div
                  class="py-1.5 pl-4 flex flex-col"
                  class:bg-green-400={(applicationFormData.pp_uri ||
                    selected_application.pp_uri) &&
                    applicationFormData.pp_uri !==
                      selected_application.pp_uri &&
                    !ppURIValidity()}
                  class:bg-red-400={selected_application.pp_uri?.length &&
                    !applicationFormData.pp_uri?.length}
                >
                  <input
                    id="privacy-policy"
                    name="Privacy Policy"
                    placeholder="https://example.com/privacy-policy.html"
                    type="link"
                    bind:value={applicationFormData.pp_uri}
                    class="h-9 px-3 mx-4 rounded-md dark:placeholder-[#808080] dark:placeholder-opacity-80 bg-white dark:bg-[#151515]"
                    class:border-red-500={ppURIValidity()}
                  />

                  {#if ppURIValidity()}
                    <span
                      data-test="pp-error-text"
                      class="px-4 mt-2 text-sm text-red-500"
                      transition:slide|local>{ppURIValidity()}</span
                    >
                  {/if}
                </div>
              </div>

              <section class="flex flex-col">
                <div class="flex flex-col">
                  <div class="text-charcoal dark:text-[#d4d4d4] mb-4 max-w-3xl">
                    <div class="inline-flex items-center px-4 mb-1">
                      <label for="logo" class="font-medium text-base"
                        >Logo for Light Mode</label
                      >
                      <button
                        aria-label="Help"
                        use:tippy={{
                          content:
                            'Max display area is 160px x 64px<br/>Accepts .png, .gif, .jpg/.jpeg, .webp, .apng',
                          allowHTML: true,
                          placement: 'top'
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 ml-1"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>

                    <div
                      id="logo-for-light-mode"
                      class="pt-1 pb-2 flex justify-start pl-8"
                      class:bg-green-400={logo}
                    >
                      <button
                        on:dragover={(e) => e.preventDefault()}
                        on:dragenter={(e) => e.preventDefault()}
                        on:drop={(e) => handleLogoDrop(e, 'light')}
                        class="group inline-flex flex-col items-center justify-center"
                        on:click={() => {
                          document
                            .getElementById('logo-upload-input-ref')
                            .click();
                        }}
                      >
                        {#if logo || applicationFormData.image_uri || darkLogo || applicationFormData.dark_image_uri}
                          <div
                            class="border bg-white dark:bg-[#151515] group-hover:border-2 group-focus-visible:border-2 border-dashed border-charcoal dark:border-[#d4d4d4] w-[12.5rem] h-[3.125rem] inline-flex items-center justify-center bg-no-repeat bg-center bg-contain"
                            style="background-image: url({logo ||
                              applicationFormData.image_uri ||
                              darkLogo ||
                              applicationFormData.dark_image_uri});"
                          >
                            {#if !darkLogo && !applicationFormData.dark_image_uri && !logo && !applicationFormData.image_uri}
                              <span class="text-center text-xs"
                                >Click to upload or drag & drop<br />(Max 150kB)</span
                              >
                            {/if}
                          </div>
                        {:else}
                          <div
                            class="border bg-white dark:bg-[#151515] group-hover:border-2 group-focus-visible:border-2 border-dashed border-charcoal dark:border-[#d4d4d4] w-[12.5rem] h-[3.125rem] inline-flex items-center justify-center"
                          >
                            {#if !darkLogo && !applicationFormData.dark_image_uri && !logo && !applicationFormData.image_uri}
                              <span class="text-center text-xs"
                                >Click to upload or drag & drop<br />(Max 150kB)</span
                              >
                            {/if}
                          </div>
                        {/if}

                        {#if darkLogo || applicationFormData.dark_image_uri || logo || applicationFormData.image_uri}
                          <span
                            class="mt-2 text-center text-xs"
                            class:text-charcoal={logo}
                            >Click to upload or drag & drop<br />(Max 150kB)</span
                          >
                        {/if}
                      </button>
                      <input
                        on:change={(e) => handleLogoUpload(e, 'light')}
                        id="logo-upload-input-ref"
                        accept=".png, .gif, .jpg, .jpeg, .webp, .apng"
                        type="file"
                        name="logo"
                        hidden
                      />
                    </div>
                  </div>
                  <div
                    id="light-preview"
                    class="bg-white text-charcoal border border-dashed border-charcoal mb-4 w-full"
                  >
                    <div
                      class="relative bg-charcoal flex items-center justify-center h-12 w-full text-white"
                    >
                      <span
                        class="absolute left-4 text-xs md:text-sm opacity-60"
                        >Light Mode Preview</span
                      >
                      <span class="text-lg font-medium">Hellō</span>
                    </div>

                    <div
                      class="px-4 pt-12 pb-8 flex items-center justify-center flex-col"
                    >
                      <button
                        tabindex="-1"
                        on:dragover={(e) => e.preventDefault()}
                        on:dragenter={(e) => e.preventDefault()}
                        on:drop={(e) => handleLogoDrop(e, 'light')}
                        class="mx-auto"
                        aria-hidden="true"
                        on:click={() => {
                          document
                            .getElementById('logo-upload-input-ref')
                            .click();
                        }}
                      >
                        {#if logo || applicationFormData.image_uri || darkLogo || applicationFormData.dark_image_uri}
                          <img
                            src={logo ||
                              applicationFormData.image_uri ||
                              darkLogo ||
                              applicationFormData.dark_image_uri}
                            alt={applicationFormData.name}
                            class="max-w-[17.5rem] max-h-[4.375rem] md:max-w-[25rem] md:max-h-[6.25rem] object-contain"
                          />
                        {:else}
                          <div
                            class="flex items-center justify-center text-red-500 border border-dashed border-gray-300 w-[17.5rem] h-[4.375rem] md:w-[25rem] md:h-[6.25rem]"
                          >
                            No logo added
                          </div>
                        {/if}
                      </button>
                      <span
                        data-test="light-preview-application-name"
                        class="text-2xl text-center font-medium mt-6 -mb-0.5"
                        >{applicationFormData.name}</span
                      >

                      <a
                        href={previewURL.href}
                        data-test="light-preview-application-url"
                        target="_blank"
                        class="font-light text-lg my-1 hover:underline focus-visible:underline"
                        >{previewURL.host}</a
                      >
                      <div class="flex items-center text-xs">
                        {#if !applicationFormData.tos_uri?.length || !checkURIValidity(applicationFormData.tos_uri)}
                          <span class="text-red-500 font-medium ml-4"
                            >No Terms of Service</span
                          >
                        {:else}
                          <a
                            href={applicationFormData.tos_uri}
                            data-test="light-preview-tos-url"
                            target="_blank"
                            class="ml-4 hover:underline focus-visible:underline focus-visible:outline-none"
                            >Terms of Service</a
                          >
                        {/if}
                        {#if !applicationFormData.pp_uri?.length || !checkURIValidity(applicationFormData.pp_uri)}
                          <span class="text-red-500 font-medium ml-4"
                            >No Privacy Policy</span
                          >
                        {:else}
                          <a
                            href={applicationFormData.pp_uri}
                            data-test="light-preview-pp-url"
                            target="_blank"
                            class="ml-4 hover:underline focus-visible:underline focus-visible:outline-none"
                            >Privacy Policy</a
                          >
                        {/if}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex flex-col">
                  <div class="text-charcoal dark:text-[#d4d4d4] mb-4 max-w-3xl">
                    <div class="inline-flex items-center px-4 mb-1">
                      <label for="dark-logo" class="font-medium text-base"
                        >Logo for Dark Mode</label
                      >
                      <button
                        aria-label="Help"
                        use:tippy={{
                          content:
                            'Max display area is 160px x 64px<br/>Accepts .png, .gif, .jpg/.jpeg, .webp, .apng',
                          allowHTML: true,
                          placement: 'top'
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 ml-1"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>

                    <div
                      id="logo-for-dark-mode"
                      class="pt-1 pb-2 flex justify-start"
                      class:bg-green-400={darkLogo}
                    >
                      <button
                        on:dragover={(e) => e.preventDefault()}
                        on:dragenter={(e) => e.preventDefault()}
                        on:drop={(e) => handleLogoDrop(e, 'dark')}
                        class="group inline-flex flex-col items-center justify-center pl-4"
                        on:click={() => {
                          document
                            .getElementById('dark-logo-upload-input-ref')
                            .click();
                        }}
                      >
                        {#if logo || applicationFormData.image_uri || darkLogo || applicationFormData.dark_image_uri}
                          <div
                            class="border bg-white dark:bg-[#151515] text-charcoal dark:text-[#d4d4d4] group-hover:border-2 group-focus-visible:border-2 border-dashed border-charcoal dark:border-[#d4d4d4] w-[12.5rem] h-[3.125rem] inline-flex items-center justify-center bg-no-repeat bg-center bg-contain"
                            style="background-image: url({darkLogo ||
                              applicationFormData.dark_image_uri ||
                              logo ||
                              applicationFormData.image_uri});"
                          >
                            {#if !darkLogo && !applicationFormData.dark_image_uri && !logo && !applicationFormData.image_uri}
                              <span class="text-center text-xs"
                                >Click to upload or drag & drop<br />(Max 150kB)</span
                              >
                            {/if}
                          </div>
                        {:else}
                          <div
                            class="border bg-white dark:bg-[#151515] text-charcoal dark:text-[#d4d4d4] group-hover:border-2 group-focus-visible:border-2 border-charcoal dark:border-[#d4d4d4] border-dashed ml-4 w-[12.5rem] h-[3.125rem] inline-flex items-center justify-center"
                          >
                            {#if !darkLogo && !applicationFormData.dark_image_uri && !logo && !applicationFormData.image_uri}
                              <span class="text-center text-xs"
                                >Click to upload or drag & drop<br />(Max 150kB)</span
                              >
                            {/if}
                          </div>
                        {/if}

                        {#if darkLogo || applicationFormData.dark_image_uri || logo || applicationFormData.image_uri}
                          <span
                            class="mt-2 text-center text-xs"
                            class:text-charcoal={darkLogo}
                            >Click to upload or drag & drop<br />(Max 150kB)</span
                          >
                        {/if}
                      </button>
                      <input
                        on:change={(e) => handleLogoUpload(e, 'dark')}
                        id="dark-logo-upload-input-ref"
                        accept=".png, .gif, .jpg, .jpeg, .webp, .apng"
                        type="file"
                        name="logo"
                        hidden
                      />
                    </div>
                  </div>

                  <div
                    id="dark-preview"
                    class="bg-[#151515] text-charcoal border border-dashed border-white w-full"
                  >
                    <div
                      class="relative bg-charcoal flex items-center justify-center h-12 w-full text-white"
                    >
                      <span
                        class="absolute left-4 text-xs md:text-sm opacity-60"
                        >Dark Mode Preview</span
                      >
                      <span class="text-lg font-medium text-[#d4d4d4]"
                        >Hellō</span
                      >
                    </div>

                    <div
                      class="px-4 pt-12 pb-8 flex items-center justify-center flex-col"
                    >
                      <button
                        tabindex="-1"
                        on:dragover={(e) => e.preventDefault()}
                        on:dragenter={(e) => e.preventDefault()}
                        on:drop={(e) => handleLogoDrop(e, 'dark')}
                        class="mx-auto"
                        aria-hidden="true"
                        on:click={() => {
                          document
                            .getElementById('dark-logo-upload-input-ref')
                            .click();
                        }}
                      >
                        {#if logo || applicationFormData.image_uri || darkLogo || applicationFormData.dark_image_uri}
                          <img
                            src={darkLogo ||
                              applicationFormData.dark_image_uri ||
                              logo ||
                              applicationFormData.image_uri}
                            alt={applicationFormData.name}
                            class="max-w-[17.5rem] max-h-[4.375rem] md:max-w-[25rem] md:max-h-[6.25rem] object-contain"
                          />
                        {:else}
                          <div
                            class="flex items-center justify-center text-red-500 border border-dashed border-gray-600 w-[17.5rem] h-[4.375rem] md:w-[25rem] md:h-[6.25rem]"
                          >
                            No logo added
                          </div>
                        {/if}
                      </button>
                      <span
                        data-test="dark-preview-application-name"
                        class="text-2xl text-[#d4d4d4] text-center font-medium mt-6 -mb-0.5"
                        >{applicationFormData.name}</span
                      >

                      <a
                        href={previewURL.href}
                        data-test="dark-preview-application-url"
                        target="_blank"
                        class="font-light text-[#d4d4d4] text-lg my-1 hover:underline focus-visible:underline focus-visible:outline-none"
                        >{previewURL.host}</a
                      >
                      <div class="flex items-center text-xs">
                        {#if !applicationFormData.tos_uri?.length || !checkURIValidity(applicationFormData.tos_uri)}
                          <span class="text-red-500 font-medium ml-4"
                            >No Terms of Service</span
                          >
                        {:else}
                          <a
                            href={applicationFormData.tos_uri}
                            data-test="dark-preview-tos-url"
                            target="_blank"
                            class="ml-4 hover:underline text-[#d4d4d4] focus-visible:underline focus-visible:outline-none"
                            >Terms of Service</a
                          >
                        {/if}
                        {#if !applicationFormData.pp_uri?.length || !checkURIValidity(applicationFormData.pp_uri)}
                          <span class="text-red-500 font-medium ml-4"
                            >No Privacy Policy</span
                          >
                        {:else}
                          <a
                            href={applicationFormData.pp_uri}
                            data-test="dark-preview-pp-url"
                            target="_blank"
                            class="ml-4 hover:underline text-[#d4d4d4] focus-visible:underline focus-visible:outline-none"
                            >Privacy Policy</a
                          >
                        {/if}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div>
              <nav
                class="bg-charcoal"
                aria-label="Application platforms navigation"
              >
                <ul class="flex items-center px-4">
                  {#each platformTabs as platform}
                    <li
                      class="relative px-3 py-2 mt-1.5 rounded-t-md inline-flex items-center justify-center"
                      class:bg-white={selectedPlatformTab === platform}
                      class:dark:bg-[#151515]={selectedPlatformTab === platform}
                    >
                      <button
                        data-test={`${platform}-tab`}
                        on:click={() => {
                          if (selectedPlatformTab === platform) return;
                          if (applicationFormDataModified()) {
                            showUnsavedChangesModal = changePlatform.bind(
                              this,
                              platform
                            );
                          } else {
                            changePlatform(platform);
                          }
                        }}
                        class="w-full h-full inline-flex items-center link {selectedPlatformTab ===
                        platform
                          ? 'text-charcoal dark:text-[#d4d4d4]'
                          : 'text-white dark:text-[#d4d4d4]'}"
                      >
                        {#if platform === 'Web'}
                          <WebIcon
                            stroke="{selectedPlatformTab === platform
                              ? 'text-charcoal'
                              : 'text-white'} dark:text-[#d4d4d4] stroke-current stroke-2"
                          />
                        {:else if platform === 'iOS'}
                          <AppleIcon
                            fill="{selectedPlatformTab === platform
                              ? 'text-charcoal'
                              : 'text-white'} dark:text-[#d4d4d4] fill-current"
                          />
                        {:else if platform === 'Android'}
                          <AndroidIcon
                            fill="{selectedPlatformTab === platform
                              ? 'text-charcoal'
                              : 'text-white'} dark:text-[#d4d4d4] fill-current"
                          />
                        {/if}
                        <span class="ml-1 text-lg font-semibold"
                          >{platform}</span
                        >
                      </button>
                    </li>
                  {/each}
                </ul>
              </nav>

              <div class="py-6 w-full space-y-4">
                {#if selectedPlatformTab === 'Web' && applicationFormData?.web && Object.keys(applicationFormData.web).length}
                  <div class="flex flex-col items-start">
                    <div class="px-4 inline-flex flex-col">
                      <label for="open-id-config" class="font-medium text-base"
                        >OpenID Configuration</label
                      >
                      <button
                        on:click={copyOpenIDConfig}
                        class="py-2 h-auto group mt-1 ml-4 btn-background-border-none text-left"
                        id="open-id-config"
                      >
                        <span class="mr-2">{openIDConfig}</span>
                        <div class="relative inline-flex items-center">
                          {#if openIDConfigCopiedTooltip}
                            <span
                              class="absolute bottom-6 -left-5 text-sm bg-green-400 p-1 rounded-md text-charcoal"
                              transition:fly={{y: 10}}
                            >
                              Copied!
                            </span>
                          {/if}

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="stroke-2 group-hover:stroke-3 group-focus-visible:stroke-3"
                              d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                            />
                          </svg>
                        </div>
                      </button>
                    </div>

                    <div class="px-4 inline-flex flex-col mt-4">
                      <label for="client-id" class="font-medium text-base"
                        >Client ID</label
                      >
                      <button
                        on:click={copy}
                        class="py-2 h-auto group mt-1 ml-4 btn-background-border-none text-left"
                        id="client-id"
                      >
                        <span class="mr-2">{selected_application.id}</span>
                        <div class="relative inline-flex items-center">
                          {#if copiedTooltip}
                            <span
                              class="absolute bottom-6 -left-5 text-sm bg-green-400 p-1 rounded-md text-charcoal"
                              transition:fly={{y: 10}}
                            >
                              Copied!
                            </span>
                          {/if}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="stroke-2 group-hover:stroke-3 group-focus-visible:stroke-3"
                              d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                            />
                          </svg>
                        </div>
                      </button>
                    </div>
                  </div>

                  <div class="px-4 flex flex-col max-w-3xl">
                    <label for="client-secret" class="font-medium text-base">
                      Client Secret(s)
                    </label>
                    {#if selected_application.secrets && Object.keys(selected_application.secrets).length}
                      <ul class="block mt-1 pl-4 space-y-3">
                        {#each Object.entries(selected_application.secrets) as [secret], index}
                          <li
                            data-test={`client-secret-${index}`}
                            class="flex items-center justify-between"
                          >
                            <span>Created {secret}</span>
                            <button
                              data-test={`delete-client-secret-${index}-btn`}
                              class="group"
                              on:click={() =>
                                (showDeleteClientSecretModal = secret)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-6 w-6 text-red-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="stroke-2 group-hover:stroke-3 group-focus-visible:stroke-3"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </button>
                          </li>
                        {/each}
                      </ul>
                    {/if}
                    <div
                      class="mt-3 ml-4 inline-flex items-center"
                      id="client-secret"
                    >
                      <button
                        data-test="create-client-secret-btn"
                        on:click={() => {
                          previousActiveElement =
                            document.activeElement || document.body;
                          showClientSecretCreateModal = true;
                        }}
                        class="btn-background inline-flex items-center justify-center px-8 !h-9"
                      >
                        {#if selected_application.secrets && Object.keys(selected_application.secrets).length}
                          Create New Client Secret
                        {:else}
                          Create
                        {/if}
                      </button>
                    </div>
                  </div>

                  <div class="w-full max-w-3xl">
                    <div class="inline-flex items-center px-4">
                      <label for="redirect-uris" class="font-medium text-base"
                        >Development Redirect URIs</label
                      >
                      <button
                        aria-label="Help"
                        use:tippy={{
                          content:
                            'Only the administrator and testers have access.',
                          placement: 'top'
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 ml-1"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>

                    <ul class="space-y-1 w-full">
                      <li
                        class="pl-8 py-1 flex items-center"
                        class:bg-red-400={!applicationFormData.web.dev[
                          '127.0.0.1'
                        ] && selected_application.web.dev['127.0.0.1']}
                        class:bg-green-400={applicationFormData.web.dev[
                          '127.0.0.1'
                        ] && !selected_application.web.dev['127.0.0.1']}
                      >
                        <input
                          id="default-redirect-uri-127"
                          type="checkbox"
                          name="default-redirect-url"
                          bind:checked={applicationFormData.web.dev[
                            '127.0.0.1'
                          ]}
                          disabled={disableRedirectEdit &&
                            applicationFormData.web.dev['127.0.0.1']}
                          class="form-checkbox rounded-sm text-charcoal dark:bg-transparent dark:text-[#808080] focus-visible:ring-0 disabled:opacity-80"
                        />
                        <label
                          for="default-redirect-uri-127"
                          class="ml-2 w-full md:w-[13.5rem] text-charcoal dark:text-[#d4d4d4]"
                          class:dark:text-charcoal={(!applicationFormData.web
                            .dev['127.0.0.1'] &&
                            selected_application.web.dev['127.0.0.1']) ||
                            (applicationFormData.web.dev['127.0.0.1'] &&
                              !selected_application.web.dev['127.0.0.1'])}
                        >
                          http://127.0.0.1:*</label
                        >
                      </li>
                      <li
                        class="pl-8 py-1 flex items-center"
                        class:bg-red-400={!applicationFormData.web.dev
                          .localhost && selected_application.web.dev.localhost}
                        class:bg-green-400={applicationFormData.web.dev
                          .localhost && !selected_application.web.dev.localhost}
                      >
                        <input
                          id="default-redirect-uri-localhost"
                          type="checkbox"
                          name="default-redirect-url"
                          bind:checked={applicationFormData.web.dev.localhost}
                          disabled={disableRedirectEdit &&
                            applicationFormData.web.dev.localhost}
                          class="form-checkbox rounded-sm text-charcoal dark:bg-transparent dark:text-[#808080] focus-visible:ring-0 disabled:opacity-80"
                        />
                        <label
                          for="default-redirect-uri-localhost"
                          class="ml-2 w-full md:w-[13.5rem] text-charcoal dark:text-[#d4d4d4]"
                          class:dark:text-charcoal={(!applicationFormData.web
                            .dev.localhost &&
                            selected_application.web.dev.localhost) ||
                            (applicationFormData.web.dev.localhost &&
                              !selected_application.web.dev.localhost)}
                        >
                          http://localhost:*</label
                        >
                      </li>

                      {#if selected_application.web?.dev?.redirect_uris?.length}
                        {#each selected_application.web?.dev.redirect_uris as redirect_uri, index}
                          <li
                            data-test={`dev-redirect-uri-${index}`}
                            class="pr-4 pl-8 py-2 flex items-center justify-between"
                            class:bg-red-400={!applicationFormData.web.dev.redirect_uris.includes(
                              redirect_uri
                            ) &&
                              selected_application.web.dev.redirect_uris.includes(
                                redirect_uri
                              )}
                          >
                            <div
                              class="w-full flex justify-between items-center"
                            >
                              <span>{redirect_uri}</span>
                              <button
                                data-test={`dev-redirect-uri-${index}-delete-btn`}
                                disabled={disableRedirectEdit}
                                on:click={() => {
                                  if (
                                    applicationFormData.web.dev.redirect_uris.includes(
                                      redirect_uri
                                    )
                                  ) {
                                    applicationFormData.web.dev.redirect_uris = applicationFormData.web.dev.redirect_uris.filter(
                                      (i) => i !== redirect_uri
                                    );
                                  } else {
                                    applicationFormData.web.dev.redirect_uris.splice(
                                      index,
                                      0,
                                      redirect_uri
                                    );
                                    applicationFormData.web.dev.redirect_uris =
                                      applicationFormData.web.dev.redirect_uris;
                                  }
                                }}
                                class:text-red-500={applicationFormData.web.dev.redirect_uris.includes(
                                  redirect_uri
                                )}
                                class="group disabled:text-gray-400"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="stroke-2 group-hover:stroke-3 group-focus-visible:stroke-3"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </svg>
                              </button>
                            </div>
                          </li>
                        {/each}
                      {/if}

                      {#if applicationFormData.web?.dev?.redirect_uris?.length}
                        {#each [...applicationFormData.web.dev.redirect_uris].filter((i) => !selected_application.web.dev.redirect_uris.includes(i)) as redirect_uri}
                          <li
                            class="pl-8 pr-4 py-2 bg-green-400 dark:text-charcoal flex items-center justify-between"
                            class:bg-red-400={!applicationFormData.web.dev.redirect_uris.includes(
                              redirect_uri
                            ) &&
                              selected_application.web.dev.redirect_uris.includes(
                                redirect_uri
                              )}
                            class:bg-green-400={applicationFormData.web.dev.redirect_uris.includes(
                              redirect_uri
                            ) &&
                              !selected_application.web.dev.redirect_uris.includes(
                                redirect_uri
                              )}
                          >
                            <div
                              class="w-full flex justify-between items-center"
                            >
                              <span>{redirect_uri}</span>
                              <button
                                disabled={disableRedirectEdit}
                                on:click={() =>
                                  (applicationFormData.web.dev.redirect_uris = applicationFormData.web.dev.redirect_uris.filter(
                                    (i) => i !== redirect_uri
                                  ))}
                                class="text-red-500 group disabled:text-gray-400"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="stroke-2 group-hover:stroke-3 group-focus-visible:stroke-3"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </svg>
                              </button>
                            </div>
                          </li>
                        {/each}
                      {/if}
                    </ul>

                    <form
                      class="mt-1 pl-8 pr-4 w-full relative flex items-center"
                      on:submit|preventDefault={(e) => {
                        applicationFormData.web.dev.redirect_uris = [
                          ...applicationFormData.web.dev.redirect_uris,
                          devRedirectURIValue
                        ];
                        devRedirectURIValue = '';
                      }}
                    >
                      <div class="relative w-full flex items-stretch">
                        <input
                          id="dev-redirect-uri"
                          name="dev-redirect-uri"
                          bind:value={devRedirectURIValue}
                          placeholder="https://example.dev/response"
                          type="url"
                          class="bg-transparent px-3 w-full rounded-md h-9 dark:placeholder-[#808080] dark:placeholder-opacity-80"
                          class:border-red-500={applicationFormData.web.dev.redirect_uris.includes(
                            devRedirectURIValue
                          ) ||
                            (devRedirectURIValue.length &&
                              devRedirectURIValidity())}
                        />
                        <button
                          data-test="add-dev-redirect-btn"
                          disabled={applicationFormData.web.dev.redirect_uris
                            .length +
                            applicationFormData.web.prod.redirect_uris.length >=
                            20 ||
                            devRedirectURIValue.length < 1 ||
                            [
                              ...applicationFormData.web.dev.redirect_uris,
                              ...applicationFormData.web.prod.redirect_uris
                            ].includes(devRedirectURIValue) ||
                            devRedirectURIValidity()}
                          class="ml-2 w-16 btn-background !h-9">Add</button
                        >
                      </div>
                    </form>
                  </div>

                  {#if devRedirectURIValue.length && applicationFormData.web.dev.redirect_uris.length + applicationFormData.web.prod.redirect_uris.length >= 20}
                    <span
                      data-test="dev-redirect-uri-error-text"
                      class="px-4 ml-4 block text-sm text-red-500"
                      style="margin-top: 0.4rem;"
                      transition:slide|local
                    >
                      Cannot have more than 20 Redirect URIs
                    </span>
                  {:else if [...applicationFormData.web.dev.redirect_uris, ...applicationFormData.web.prod.redirect_uris].includes(devRedirectURIValue)}
                    <span
                      data-test="dev-redirect-uri-error-text"
                      class="px-4 ml-4 -mt-2 block text-sm text-red-500"
                      style="margin-top: 0.4rem;"
                      transition:slide|local
                    >
                      URI must be unique
                    </span>
                  {:else if devRedirectURIValidity()}
                    <span
                      data-test="dev-redirect-uri-error-text"
                      class="px-4 ml-4 -mt-2 block text-sm text-red-500"
                      style="margin-top: 0.4rem;"
                      transition:slide|local
                    >
                      {devRedirectURIValidity()}
                    </span>
                  {/if}

                  <div class="w-full max-w-3xl">
                    <div class="inline-flex items-center px-4">
                      <label for="redirect-uris" class="font-medium text-base"
                        >Production Redirect URIs</label
                      >
                      <button
                        aria-label="Help"
                        use:tippy={{
                          content: 'Everyone has access.',
                          placement: 'top'
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 ml-1"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>

                    {#if !applicationFormData.web?.prod?.redirect_uris?.length && !selected_application?.web.prod?.redirect_uris?.length}
                      <p class="px-4 ml-4 text-xs my-2 opacity-60">
                        NO PRODUCTION REDIRECT URIS ADDED
                      </p>
                    {/if}
                    <ul class="mt-1 space-y-1 w-full">
                      {#if selected_application.web?.prod?.redirect_uris?.length}
                        {#each selected_application.web.prod.redirect_uris as redirect_uri, index}
                          <li
                            data-test={`prod-redirect-uri-${index}`}
                            class="pr-4 pl-8 py-1 flex items-center justify-between"
                            class:bg-red-400={!applicationFormData.web.prod.redirect_uris.includes(
                              redirect_uri
                            ) &&
                              selected_application.web.prod.redirect_uris.includes(
                                redirect_uri
                              )}
                          >
                            <div
                              class="w-full flex justify-between items-center"
                            >
                              <span>{redirect_uri}</span>
                              <button
                                data-test={`prod-redirect-uri-${index}-delete-btn`}
                                disabled={disableRedirectEdit}
                                on:click={() => {
                                  if (
                                    applicationFormData.web.prod.redirect_uris.includes(
                                      redirect_uri
                                    )
                                  ) {
                                    applicationFormData.web.prod.redirect_uris = applicationFormData.web.prod.redirect_uris.filter(
                                      (i) => i !== redirect_uri
                                    );
                                  } else {
                                    applicationFormData.web.prod.redirect_uris.splice(
                                      index,
                                      0,
                                      redirect_uri
                                    );
                                    applicationFormData.web.prod.redirect_uris =
                                      applicationFormData.web.prod.redirect_uris;
                                  }
                                }}
                                class:text-red-500={applicationFormData.web.prod.redirect_uris.includes(
                                  redirect_uri
                                )}
                                class="disabled:text-gray-400 group"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="stroke-2 group-hover:stroke-3 group-focus-visible:stroke-3"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </svg>
                              </button>
                            </div>
                          </li>
                        {/each}
                      {/if}

                      {#if applicationFormData.web?.prod?.redirect_uris?.length}
                        {#each [...applicationFormData.web.prod.redirect_uris].filter((i) => !selected_application.web.prod.redirect_uris.includes(i)) as redirect_uri}
                          <li
                            class="pl-8 pr-4 py-2 bg-green-400 dark:text-charcoal flex items-center justify-between"
                            class:bg-red-400={!applicationFormData.web.prod.redirect_uris.includes(
                              redirect_uri
                            ) &&
                              selected_application.web.prod.redirect_uris.includes(
                                redirect_uri
                              )}
                            class:bg-green-400={applicationFormData.web.prod.redirect_uris.includes(
                              redirect_uri
                            ) &&
                              !selected_application.web.prod.redirect_uris.includes(
                                redirect_uri
                              )}
                          >
                            <div
                              class="w-full flex justify-between items-center"
                            >
                              <span>{redirect_uri}</span>
                              <button
                                disabled={disableRedirectEdit}
                                on:click={() =>
                                  (applicationFormData.web.prod.redirect_uris = applicationFormData.web.prod.redirect_uris.filter(
                                    (i) => i !== redirect_uri
                                  ))}
                                class="text-red-500 disabled:text-gray-400 group"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="stroke-2 group-hover:stroke-3 group-focus-visible:stroke-3"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </svg>
                              </button>
                            </div>
                          </li>
                        {/each}
                      {/if}
                    </ul>

                    <form
                      class="mt-1 pl-8 pr-4 w-full relative flex items-center"
                      on:submit|preventDefault={(e) => {
                        applicationFormData.web.prod.redirect_uris = [
                          ...applicationFormData.web.prod.redirect_uris,
                          prodRedirectURIValue
                        ];
                        prodRedirectURIValue = '';
                      }}
                    >
                      <div class="relative w-full flex items-stretch">
                        <input
                          id="prod-redirect-uri"
                          name="prod-redirect-uri"
                          bind:value={prodRedirectURIValue}
                          placeholder="https://example.prod/response"
                          type="url"
                          class="bg-transparent px-3 w-full rounded-md h-9 dark:placeholder-[#808080] dark:placeholder-opacity-80"
                          class:border-red-500={applicationFormData.web.prod.redirect_uris.includes(
                            prodRedirectURIValue
                          ) ||
                            (prodRedirectURIValue.length &&
                              prodRedirectURIValidity())}
                        />
                        <button
                          data-test="add-prod-redirect-btn"
                          disabled={applicationFormData.web.dev.redirect_uris
                            .length +
                            applicationFormData.web.prod.redirect_uris.length >=
                            20 ||
                            prodRedirectURIValue.length < 1 ||
                            [
                              ...applicationFormData.web.dev.redirect_uris,
                              ...applicationFormData.web.prod.redirect_uris
                            ].includes(prodRedirectURIValue) ||
                            prodRedirectURIValidity()}
                          class="ml-2 w-16 btn-background !h-9">Add</button
                        >
                      </div>
                    </form>
                  </div>

                  {#if devRedirectURIValue.length && applicationFormData.web.dev.redirect_uris.length + applicationFormData.web.prod.redirect_uris.length >= 20}
                    <span
                      data-test="prod-redirect-uri-error-text"
                      class="px-4 ml-4 block text-sm text-red-500"
                      style="margin-top: 0.4rem;"
                      transition:slide|local
                    >
                      Cannot have more than 20 Redirect URIs
                    </span>
                  {:else if [...applicationFormData.web.dev.redirect_uris, ...applicationFormData.web.prod.redirect_uris].includes(prodRedirectURIValue)}
                    <span
                      data-test="prod-redirect-uri-error-text"
                      class="px-4 ml-4 -mt-2 block text-sm text-red-500"
                      style="margin-top: 0.4rem;"
                      transition:slide|local
                    >
                      URI must be unique
                    </span>
                  {:else if prodRedirectURIValidity()}
                    <span
                      data-test="prod-redirect-uri-error-text"
                      class="px-4 ml-4 -mt-2 block text-sm text-red-500"
                      style="margin-top: 0.4rem;"
                      transition:slide|local
                    >
                      {prodRedirectURIValidity()}
                    </span>
                  {/if}
                {:else}
                  <div
                    class="flex-1 max-w-3xl h-96 bg-charcoal dark:bg-[#808080] bg-opacity-20 dark:bg-opacity-10 text-charcoal text-opacity-80 dark:text-[#d4d4d4] flex items-center justify-center mx-4"
                  >
                    Coming Soon
                  </div>
                {/if}
              </div>
            </div>
          {/if}

          <div
            class="gap-4 flex justify-between flex-wrap items-center max-w-3xl"
          >
            <div class="flex justify-between items-center">
              {#if applicationFormDataModified()}
                <div class="flex px-4">
                  <!-- TODO: Refactor needed here, calling checkURIValidity function twice (here and in GenericInput component) -->
                  <button
                    data-test="save-application-changes-btn"
                    on:click={updateApplication}
                    class="btn-background flex-shrink-0 !w-36 !h-9 block"
                    disabled={saveDisabled()}
                  >
                    {#if updateApplicationAjax}
                      <svg
                        class="animate-spin h-5 w-5 block mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        />
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    {:else}
                      Save changes
                    {/if}
                  </button>

                  <button
                    data-test="discard-application-changes-btn"
                    on:click={discardAppChanges}
                    class="btn-border ml-2 flex-shrink-0 !w-36 !h-9 block"
                  >
                    Discard changes
                  </button>
                </div>
              {/if}
            </div>

            {#if $data.defaultPublisher}
              <div class="px-4 ml-auto">
                <button
                  data-test="delete-application-btn"
                  on:click={() => (showDeleteApplicationModal = true)}
                  class="w-40 h-9 btn-danger">Delete Application</button
                >
              </div>
            {/if}
          </div>
        {:else if $data.defaultPublisher}
          <div class="p-4">No applications created.</div>
        {:else}
          <div class="p-4">
            Create a publisher first to create an application.
          </div>
        {/if}
      </section>
    </main>
  {/if}

  {#if $showLoginPage}
    <Login {login} {loginAjax} />
  {/if}
{/if}
