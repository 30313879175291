<script>
  export let disabled = true;
</script>

<button
  on:click
  {disabled}
  class="disabled:opacity-50 bg-charcoal border-2 border-charcoal dark:border-[#808080] disabled:cursor-not-allowed text-sm rounded-md h-7 w-16 text-white"
>
  Invite
</button>
