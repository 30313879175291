<script>
  import {fly, fade} from 'svelte/transition';
  import {notification} from '../../stores.js';
  import {createEventDispatcher} from 'svelte';
  import {deleteClientSecret} from '../../utils/api-calls.js';
  import CloseIcon from '../icon/CloseIcon.svelte';

  const dispatch = createEventDispatcher();
  export let application = {};
  export let pub_id;
  export let secret;
  let value = '';
  let delClientSecretAjax = false;

  async function delClientSecret() {
    try {
      delClientSecretAjax = true;
      await deleteClientSecret({
        pub_id,
        app_id: application.id,
        secret
      });
      dispatch('success');
      dispatch('close');
    } catch (err) {
      console.error(err);
      if (err.status === 404) {
        const json = await err.json();
        if (json.error === 'SECRET_NOT_FOUND') {
          $notification = {
            text: 'Secret Not Found',
            type: 'error'
          };
        }
      }
      delClientSecretAjax = false;
    }
  }

  const handleEscKeyClose = (e) => {
    //27 escpae key
    if (e.keyCode === 27) {
      dispatch('close');
    }
  };
</script>

<svelte:window on:keydown={handleEscKeyClose} />

<div
  data-test="delete-client-secret-modal"
  class="fixed z-10 inset-0 overflow-y-auto"
  aria-labelledby="delete-client-secret-modal"
  role="dialog"
  aria-modal="true"
  transition:fly|local={{y: 20}}
>
  <div
    class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div
      on:click={() => dispatch('close')}
      class="fixed inset-0 bg-black bg-opacity-80 transition-opacity"
      aria-hidden="true"
      in:fade|local={{duration: 200}}
      out:fade|local={{duration: 100}}
    />

    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true">&#8203;</span
    >

    <div
      class="inline-block align-bottom bg-white dark:bg-[#151515] dark:border dark:border-[#808080] rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full"
    >
      <button
        aria-label="Close"
        on:click={() => dispatch('close')}
        class="absolute right-5 top-5 group"
      >
        <CloseIcon />
      </button>

      <form
        class="p-8 flex flex-col"
        on:submit|preventDefault={delClientSecret}
      >
        <h2 class="text-center text-lg font-semibold">
          Confirm Deleting <br />Client Secret<br />
          {#if application.secrets}
            {secret}
          {/if}
        </h2>
        <p class="text-center mt-3">
          Ensure your application is no longer using this secret. Please type
          ‘DELETE’ to confirm
        </p>

        <!-- svelte-ignore a11y-autofocus -->
        <input
          data-test="confirm-delete-client-secret-input"
          autofocus
          type="text"
          bind:value
          placeholder="DELETE"
          class="mt-2 dark:placeholder-[#808080] dark:placeholder-opacity-80 rounded-md h-9 px-3 bg-transparent"
        />
        <button
          data-test="confirm-delete-client-secret-btn"
          disabled={value !== 'DELETE' || delClientSecretAjax}
          class="!w-full !h-16 mt-4 btn-danger-background"
        >
          {#if delClientSecretAjax}
            <svg
              class="animate-spin h-5 w-5 block mx-auto text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              />
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          {:else}
            Delete Client Secret <br />
            {#if application.secrets}
              {secret}
            {/if}
          {/if}
        </button>
      </form>
    </div>
  </div>
</div>
