import {get, post, put, del} from './api.js';

export const getToken = async (code) => {
  const code_verifier = localStorage.getItem('pkce_code_verifier');
  const params = {
    code: code,
    client_id: 'hello_console',
    redirect_uri: window.location.origin + '/',
    grant_type: 'authorization_code',
    code_verifier
  };

  const url = window.endpoints.token_endpoint

  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams(params).toString()
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      if (!data.access_token) return;
      sessionStorage.setItem('token', data.access_token);
    })
    .finally(() => {
      localStorage.removeItem('pkce_code_verifier');
      localStorage.removeItem('pkce_state');

      window.location.replace('#');

      if (typeof window.history.replaceState == 'function') {
        history.replaceState({}, '', window.location.href.slice(0, -1));
      }
    });
};

export const getProfile = async () => {
  return get('/profile');
};

export const getPublisher = async (id) => {
  return get('/publishers/' + id);
};

export const putPublisher = async (id, body) => {
  return put('/publishers/' + id, body);
};

export const putApplication = async (pub_id, app_id, body) => {
  return put('/publishers/' + pub_id + '/applications/' + app_id, body);
};

export const postPublisher = async (body) => {
  return post('/publishers', body);
};

export const postApplication = async (pub_id, body) => {
  return post('/publishers/' + pub_id + '/applications', body);
};

export const deleteApplication = async (pub_id, app_id) => {
  return del('/publishers/' + pub_id + '/applications/' + app_id);
};

export const deletePublisher = async (id) => {
  return del('/publishers/' + id);
};

export const postClientSecret = async ({hash, salt, pub_id, app_id} = {}) => {
  return post(
    '/publishers/' + pub_id + '/applications/' + app_id + '/secrets',
    {hash, salt}
  );
};

export const deleteClientSecret = async ({pub_id, app_id, secret} = {}) => {
  return del(
    '/publishers/' + pub_id + '/applications/' + app_id + '/secrets/' + secret
  );
};
