<script>
  import {fly, fade} from 'svelte/transition';
  import {data} from '../../stores.js';
  import {createEventDispatcher} from 'svelte';
  import {deleteApplication} from '../../utils/api-calls.js';
  import CloseIcon from '../icon/CloseIcon.svelte';

  const dispatch = createEventDispatcher();
  export let application = {};
  let value = '';
  let delAppAjax = false;

  async function delApp() {
    try {
      delAppAjax = true;
      const publisher_id = $data.defaultPublisher.profile.id;
      await deleteApplication(publisher_id, application.id);
      $data.defaultPublisher.applications = $data.defaultPublisher.applications.filter(
        (i) => i.id !== application.id
      );
      dispatch('success');
      dispatch('close');
    } catch (err) {
      console.error(err);
      delAppAjax = false;
    }
  }

  const handleEscKeyClose = (e) => {
    //27 escpae key
    if (e.keyCode === 27) {
      dispatch('close');
    }
  };
</script>

<svelte:window on:keydown={handleEscKeyClose} />

<div
  data-test="delete-application-modal"
  class="fixed z-10 inset-0 overflow-y-auto"
  aria-label="Delete app"
  role="dialog"
  aria-modal="true"
  transition:fly|local={{y: 20}}
>
  <div
    class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div
      on:click={() => dispatch('close')}
      class="fixed inset-0 bg-black bg-opacity-80 transition-opacity"
      aria-hidden="true"
      in:fade|local={{duration: 200}}
      out:fade|local={{duration: 100}}
    />

    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true">&#8203;</span
    >

    <div
      class="inline-block align-bottom bg-white dark:bg-[#151515] dark:border dark:border-[#808080] rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full"
    >
      <button
        aria-label="Close"
        on:click={() => dispatch('close')}
        class="absolute right-5 top-5 group"
      >
        <CloseIcon />
      </button>

      <form class="p-8 flex flex-col" on:submit|preventDefault={delApp}>
        <h2 class="text-center text-lg font-semibold px-4 truncate">
          Confirm Deleting <br />{application.name}
        </h2>
        <p class="text-center mt-3">
          Once you delete an application, it cannot be recovered. Please type
          'DELETE' to confirm.
        </p>

        <!-- svelte-ignore a11y-autofocus -->
        <input
          data-test="confirm-delete-application-input"
          autofocus
          type="text"
          bind:value
          placeholder="DELETE"
          class="mt-4 rounded-md h-9 px-3 bg-transparent dark:placeholder-[#808080] dark:placeholder-opacity-80"
        />
        <button
          data-test="confirm-delete-application-btn"
          disabled={value !== 'DELETE' || delAppAjax}
          class="w-full h-16 mt-4 btn-danger-background px-4 truncate"
        >
          <span class="w-3/4 truncate">
            {#if delAppAjax}
              <svg
                class="animate-spin h-5 w-5 block mx-auto text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                />
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            {:else}
              Permanently Delete <br />
              {application.name}
            {/if}
          </span>
        </button>
      </form>
    </div>
  </div>
</div>
