<script>
  import {notification} from '../stores.js';
  import {clickOutside} from '../utils/helper.js';
  import {slide} from 'svelte/transition';
  import CloseIcon from './icon/CloseIcon.svelte';
</script>

{#if $notification}
  <div
    class="flex items-center relative z-[100]"
    use:clickOutside={() => ($notification = null)}
  >
    <div
      class="w-full flex items-center justify-center px-4 {$notification.type ===
      'success'
        ? 'bg-green-500'
        : $notification.type === 'error'
        ? 'bg-red-500'
        : ''} text-sm text-white h-12"
      transition:slide
    >
      {$notification.text}
    </div>

    <div class="absolute right-8 inline-flex items-center justify-end">
      <button
        aria-label="Close"
        on:click={() => ($notification = null)}
        class="group"
      >
        <CloseIcon />
      </button>
    </div>
  </div>
{/if}
