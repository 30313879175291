import {get} from './api.js';
import {version} from '../../../package.json';

const URI_ERRORS = {
  INVALID: 'Invalid URI',
  MUST_HTTP: 'Must start with http:// or https://',
  MUST_HTTPS: 'Must start with https://',
  NOT_LOCALHOST: 'Must not be localhost',
  NOT_FRAGMENT: 'Must not have a fragment'
};

const checkURIValidity = (value) => {
  try {
    const uri = new URL(value);
    if (uri.origin == value) return false; // There is no path
    return true;
  } catch {
    return false;
  }
};

// TODO: Write unit tests for these fns and break into util/uri_validator.js
const checkTosPpUriError = (value) => {
  if (!checkURIValidity(value)) return URI_ERRORS.INVALID;
  if (!value.startsWith('http://') && !value.startsWith('https://'))
    return URI_ERRORS.MUST_HTTP;
  return false;
};

const checkDevURIError = (value) => {
  if (!checkURIValidity(value)) return URI_ERRORS.INVALID;
  if (value.includes('#')) return URI_ERRORS.NOT_FRAGMENT;
  return false;
};

const checkProdURIError = (value) => {
  //TODO: Compose checkDevURIError fn
  if (!checkURIValidity(value)) return URI_ERRORS.INVALID;
  if (value.startsWith('http://')) return URI_ERRORS.MUST_HTTPS;
  if (
    (value.startsWith('https://localhost') &&
      !value.startsWith('https://localhost.')) ||
    value.startsWith('https://127.0.0.1')
  )
    return URI_ERRORS.NOT_LOCALHOST;
  if (value.includes('#')) return URI_ERRORS.NOT_FRAGMENT;
  return false;
};

const clickOutside = (element, callbackFunction) => {
  function onClick(event) {
    if (!element.contains(event.target)) {
      callbackFunction();
    }
  }

  document.body.addEventListener('click', onClick);

  return {
    update(newCallbackFunction) {
      callbackFunction = newCallbackFunction;
    },
    destroy() {
      document.body.removeEventListener('click', onClick);
    }
  };
};

const sendEvent = async (body) => {
  if (localStorage.getItem('plausible_ignore') == 'true') {
    console.info('Ignoring Event: localStorage flag');
    return;
  }
  const _body = {
    w: window.innerWidth,
    r: document.referrer || null,
    d: window.location.hostname,
    ...body,
    n: body.n || 'pageview',
    u: new URL(body.u, window.location.origin)
  };
  // const url = new URL('/api/event');
  try {
    await fetch('/api/event', {
      method: 'POST',
      body: JSON.stringify(_body)
    });
    console.info(`Event sent: ${_body.u} (${_body.n})`);
  } catch (err) {
    console.error(err);
  }
};

const endpoints = findEndpoints();

function findEndpoints() {  
  const domains = {
    development: "hello-dev.net",
    "dev-local": "hello-local.net", //"local" cannot be used as a mode name because it conflicts with the .local postfix for .env files.
    staging: "hello-staging.net",
    beta: "hello-staging.net",
    production: "hello.coop",
  }
  const domain = domains[import.meta.env.MODE]
  const endpoints = {
    api: 'https://admin.' + domain,
    authorization_server: 'https://wallet.' + domain + '/authorize',
    token_endpoint: 'https://wallet.' + domain + '/oauth/token',
    issuer: 'https://issuer.' + domain + '/.well-known/openid-configuration'
  };
  return endpoints;
}

const checkVersion = async () => {
  const result = await get('/version');
  const versionServer = result && result.VERSION;
  if (versionServer) {
    console.log(
      `Running client v${version}\nAccessing server v${versionServer}`
    );
  }
};

function generateRandomString() {
  var array = new Uint32Array(28);
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join(
    ''
  );
}

// Calculate the SHA256 hash of the input text.
// Returns a promise that resolves to an ArrayBuffer
function sha256(plain) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
}

// Base64-urlencodes the input string
function base64urlencode(str) {
  // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
  // btoa accepts chars only within ascii 0-255 and base64 encodes them.
  // Then convert the base64 encoded to base64url encoded
  //   (replace + with -, replace / with _, trim trailing =)
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}

// Return the base64-urlencoded sha256 hash for the PKCE challenge
async function pkceChallengeFromVerifier(v) {
  let hashed = await sha256(v);
  return base64urlencode(hashed);
}

export {
  checkURIValidity,
  checkTosPpUriError,
  checkDevURIError,
  checkProdURIError,
  clickOutside,
  sendEvent,
  endpoints,
  checkVersion,
  generateRandomString,
  pkceChallengeFromVerifier
};
