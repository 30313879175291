<script>
  import {fly, fade} from 'svelte/transition';
  import {data} from '../../stores.js';
  import {onMount, createEventDispatcher} from 'svelte';
  import {postPublisher, postApplication} from '../../utils/api-calls.js';
  import tippy from 'sveltejs-tippy';
  import CloseIcon from '../icon/CloseIcon.svelte';

  const dispatch = createEventDispatcher();
  let pubValue = '';
  let appValue = '';
  let createPubAndAppAjax = false;

  onMount(() => {
    if ($data.isNewAdmin) {
      const adminName = $data.admin.name;
      pubValue = adminName + `'s Team`;
      appValue = adminName + `'s Application`;
    }
  });

  $: createButtonDisabled = () => {
    if (pubValue.length < 1 || appValue.length < 1 || createPubAndAppAjax)
      return true;
    else return false;
  };

  async function createPubAndApp() {
    if (createButtonDisabled()) return;

    createPubAndAppAjax = true;
    postPublisher({
      name: pubValue
    })
      .then((res) => {
        /**
         * API does not send createdAt property on POST call.
         * Added here for sorting to work properly.
         */
        res.createdAt = new Date().toISOString();

        $data.defaultPublisher = {};
        $data.defaultPublisher.profile = res;
        $data.publishers = [...$data.publishers, res];
        const body = {
          name: appValue,
          tos_uri: null,
          pp_uri: null,
          image_uri: null,
          web: {
            dev: {
              localhost: true,
              '127.0.0.1': true,
              redirect_uris: []
            },
            prod: {
              redirect_uris: []
            }
          },
          createdBy: "console"
        };

        return postApplication(res.id, body);
      })
      .then((res) => {
        /**
         * API does not send createdAt property on POST call.
         * Added here for sorting to work properly.
         */
        res.createdAt = new Date().toISOString();

        $data.defaultPublisher.applications = [];
        $data.defaultPublisher.applications = [
          ...$data.defaultPublisher.applications,
          res
        ];
        $data.isNewAdmin = false;

        dispatch('success');
        dispatch('close');
      })
      .catch((err) => {
        createPubAndAppAjax = false;
        console.error(err);
      });
  }

  function closeModal() {
    $data.isNewAdmin = false;
    dispatch('close');
  }

  const handleEscKeyClose = (e) => {
    //27 escpae key
    if (e.keyCode === 27) {
      dispatch('close');
    }
  };
</script>

<svelte:window on:keydown={handleEscKeyClose} />

<div
  data-test="create-publisher-application-modal"
  class="fixed z-10 inset-0 overflow-y-auto"
  aria-label="Create publisher and application"
  role="dialog"
  aria-modal="true"
  transition:fly|local={{y: 20}}
>
  <div
    class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div
      on:click={closeModal}
      class="fixed inset-0 bg-black bg-opacity-80 transition-opacity"
      aria-hidden="true"
      in:fade|local={{duration: 200}}
      out:fade|local={{duration: 100}}
    />

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true">&#8203;</span
    >

    <div
      class="inline-block align-bottom bg-white dark:bg-[#151515] dark:border dark:border-[#808080] rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full"
    >
      <button
        data-test="close-btn"
        on:click={closeModal}
        class="group absolute right-5 top-5 text-charcoal dark:text-[#d4d4d4]"
        aria-label="Close"
      >
        <CloseIcon />
      </button>

      <form
        class="p-8 flex flex-col"
        on:submit|preventDefault={createPubAndApp}
      >
        <div>
          <div class="inline-flex items-center">
            <label for="pub_name" class="block font-semibold"
              >Publisher Name</label
            >
            <button
              aria-label="Help"
              use:tippy={{
                content:
                  'A publisher is one or more people working on one or more applications.',
                placement: 'top'
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 ml-1"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>

          <!-- svelte-ignore a11y-autofocus -->
          <input
            bind:value={pubValue}
            id="pub_name"
            type="text"
            name="pub_name"
            autofocus
            required
            class="w-full mt-2 rounded-md h-9 px-3 bg-transparent"
          />
        </div>

        <div class="mt-4">
          <label for="app_name" class="block font-semibold"
            >Application Name</label
          >
          <input
            bind:value={appValue}
            id="app_name"
            type="text"
            name="app_name"
            required
            class="w-full mt-2 rounded-md h-9 px-3 bg-transparent"
          />
        </div>

        <button
          data-test="create-pub-app-btn"
          type="submit"
          disabled={createButtonDisabled()}
          class="mt-6 !h-10 btn-background disabled:opacity-50"
        >
          {#if createPubAndAppAjax}
            <svg
              class="animate-spin h-5 w-5 block mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              />
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          {:else}
            Create Publisher and Application
          {/if}
        </button>
      </form>
    </div>
  </div>
</div>
